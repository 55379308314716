<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3538 12.973L17.3538 3.53803C17.3489 3.53377 17.3443 3.52917 17.34 3.52428C16.9718 3.18945 16.492 3.00391 15.9944 3.00391C15.4967 3.00391 15.0169 3.18945 14.6488 3.52428L14.635 3.53803L4.64625 12.973C4.4425 13.1604 4.27985 13.388 4.16861 13.6415C4.05737 13.8949 3.99996 14.1687 4 14.4455V26.0018C4 26.5322 4.21071 27.0409 4.58579 27.416C4.96086 27.7911 5.46957 28.0018 6 28.0018H12C12.5304 28.0018 13.0391 27.7911 13.4142 27.416C13.7893 27.0409 14 26.5322 14 26.0018V20.0018H18V26.0018C18 26.5322 18.2107 27.0409 18.5858 27.416C18.9609 27.7911 19.4696 28.0018 20 28.0018H26C26.5304 28.0018 27.0391 27.7911 27.4142 27.416C27.7893 27.0409 28 26.5322 28 26.0018V14.4455C28 14.1687 27.9426 13.8949 27.8314 13.6415C27.7201 13.388 27.5575 13.1604 27.3538 12.973ZM26 26.0018H20V20.0018C20 19.4713 19.7893 18.9626 19.4142 18.5876C19.0391 18.2125 18.5304 18.0018 18 18.0018H14C13.4696 18.0018 12.9609 18.2125 12.5858 18.5876C12.2107 18.9626 12 19.4713 12 20.0018V26.0018H6V14.4455L6.01375 14.433L16 5.00178L25.9875 14.4305L26.0012 14.443L26 26.0018Z"
      fill="currentColor"
    />
  </svg>
</template>
