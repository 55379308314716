<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2362 6.8325C24.04 5.62422 22.6172 4.66377 21.0492 4.00613C19.4813 3.3485 17.799 3.00659 16.0987 3H16C12.5522 3 9.24558 4.36964 6.80761 6.80761C4.36964 9.24558 3 12.5522 3 16V23C3 23.7956 3.31607 24.5587 3.87868 25.1213C4.44129 25.6839 5.20435 26 6 26H8C8.79565 26 9.55871 25.6839 10.1213 25.1213C10.6839 24.5587 11 23.7956 11 23V18C11 17.2044 10.6839 16.4413 10.1213 15.8787C9.55871 15.3161 8.79565 15 8 15H5.045C5.23785 12.9149 6.02104 10.928 7.30278 9.27213C8.58452 7.61626 10.3117 6.36004 12.2819 5.65071C14.2521 4.94137 16.3836 4.80832 18.4267 5.26713C20.4698 5.72595 22.3398 6.75763 23.8175 8.24125C25.6236 10.0566 26.7345 12.4488 26.9562 15H24C23.2044 15 22.4413 15.3161 21.8787 15.8787C21.3161 16.4413 21 17.2044 21 18V23C21 23.7956 21.3161 24.5587 21.8787 25.1213C22.4413 25.6839 23.2044 26 24 26H27C27 26.7956 26.6839 27.5587 26.1213 28.1213C25.5587 28.6839 24.7956 29 24 29H17C16.7348 29 16.4804 29.1054 16.2929 29.2929C16.1054 29.4804 16 29.7348 16 30C16 30.2652 16.1054 30.5196 16.2929 30.7071C16.4804 30.8946 16.7348 31 17 31H24C25.3261 31 26.5979 30.4732 27.5355 29.5355C28.4732 28.5979 29 27.3261 29 26V16C29.0065 14.2995 28.6774 12.6144 28.0316 11.0412C27.3857 9.46811 26.4358 8.03788 25.2362 6.8325ZM8 17C8.26522 17 8.51957 17.1054 8.70711 17.2929C8.89464 17.4804 9 17.7348 9 18V23C9 23.2652 8.89464 23.5196 8.70711 23.7071C8.51957 23.8946 8.26522 24 8 24H6C5.73478 24 5.48043 23.8946 5.29289 23.7071C5.10536 23.5196 5 23.2652 5 23V17H8ZM24 24C23.7348 24 23.4804 23.8946 23.2929 23.7071C23.1054 23.5196 23 23.2652 23 23V18C23 17.7348 23.1054 17.4804 23.2929 17.2929C23.4804 17.1054 23.7348 17 24 17H27V24H24Z"
      fill="currentColor"
    />
  </svg>
</template>
