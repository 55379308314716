<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 9H26V8C26 7.20435 25.6839 6.44129 25.1213 5.87868C24.5587 5.31607 23.7956 5 23 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V20C2 20.7956 2.31607 21.5587 2.87868 22.1213C3.44129 22.6839 4.20435 23 5 23H19V24C19 24.7956 19.3161 25.5587 19.8787 26.1213C20.4413 26.6839 21.2044 27 22 27H28C28.7956 27 29.5587 26.6839 30.1213 26.1213C30.6839 25.5587 31 24.7956 31 24V12C31 11.2044 30.6839 10.4413 30.1213 9.87868C29.5587 9.31607 28.7956 9 28 9ZM5 21C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H23C23.2652 7 23.5196 7.10536 23.7071 7.29289C23.8946 7.48043 24 7.73478 24 8V9H22C21.2044 9 20.4413 9.31607 19.8787 9.87868C19.3161 10.4413 19 11.2044 19 12V21H5ZM29 24C29 24.2652 28.8946 24.5196 28.7071 24.7071C28.5196 24.8946 28.2652 25 28 25H22C21.7348 25 21.4804 24.8946 21.2929 24.7071C21.1054 24.5196 21 24.2652 21 24V12C21 11.7348 21.1054 11.4804 21.2929 11.2929C21.4804 11.1054 21.7348 11 22 11H28C28.2652 11 28.5196 11.1054 28.7071 11.2929C28.8946 11.4804 29 11.7348 29 12V24ZM17 26C17 26.2652 16.8946 26.5196 16.7071 26.7071C16.5196 26.8946 16.2652 27 16 27H11C10.7348 27 10.4804 26.8946 10.2929 26.7071C10.1054 26.5196 10 26.2652 10 26C10 25.7348 10.1054 25.4804 10.2929 25.2929C10.4804 25.1054 10.7348 25 11 25H16C16.2652 25 16.5196 25.1054 16.7071 25.2929C16.8946 25.4804 17 25.7348 17 26ZM27 14C27 14.2652 26.8946 14.5196 26.7071 14.7071C26.5196 14.8946 26.2652 15 26 15H24C23.7348 15 23.4804 14.8946 23.2929 14.7071C23.1054 14.5196 23 14.2652 23 14C23 13.7348 23.1054 13.4804 23.2929 13.2929C23.4804 13.1054 23.7348 13 24 13H26C26.2652 13 26.5196 13.1054 26.7071 13.2929C26.8946 13.4804 27 13.7348 27 14Z"
      fill="currentColor"
    />
  </svg>
</template>
