<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 5C18 4.73478 18.1054 4.48043 18.2929 4.29289C18.4804 4.10536 18.7348 4 19 4H26C26.5304 4 27.0391 4.21071 27.4142 4.58579C27.7893 4.96086 28 5.46957 28 6L28 26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H19C18.7348 28 18.4804 27.8946 18.2929 27.7071C18.1054 27.5196 18 27.2652 18 27C18 26.7348 18.1054 26.4804 18.2929 26.2929C18.4804 26.1054 18.7348 26 19 26H26L26 6H19C18.7348 6 18.4804 5.89464 18.2929 5.70711C18.1054 5.51957 18 5.26522 18 5ZM4.2925 16.7075L9.2925 21.7075C9.48014 21.8951 9.73464 22.0006 10 22.0006C10.2654 22.0006 10.5199 21.8951 10.7075 21.7075C10.8951 21.5199 11.0006 21.2654 11.0006 21C11.0006 20.7346 10.8951 20.4801 10.7075 20.2925L7.41375 17H19C19.2652 17 19.5196 16.8946 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16C20 15.7348 19.8946 15.4804 19.7071 15.2929C19.5196 15.1054 19.2652 15 19 15H7.41375L10.7075 11.7075C10.8951 11.5199 11.0006 11.2654 11.0006 11C11.0006 10.7346 10.8951 10.4801 10.7075 10.2925C10.5199 10.1049 10.2654 9.99944 10 9.99944C9.73464 9.99944 9.48014 10.1049 9.2925 10.2925L4.2925 15.2925C4.19952 15.3854 4.12576 15.4957 4.07544 15.6171C4.02512 15.7385 3.99921 15.8686 3.99921 16C3.99921 16.1314 4.02512 16.2615 4.07544 16.3829C4.12576 16.5043 4.19952 16.6146 4.2925 16.7075Z"
      fill="currentColor"
    />
  </svg>
</template>
